import { useMemo } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import ThemeForm, { initialThemeValues } from '../../components/ThemeForm'
import { MAIN_LANG } from '../../consts'
import { useCreateTheme, useTheme } from '../../hooks/themes'
import { ThemeDetail } from '../../types'

export default function ThemeCreate() {
  const createDoc = useCreateTheme()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const parentThemeId = queryParams.get('parent') ?? null
  const { data: parentTheme } = useTheme(+parentThemeId!, {
    enabled: Boolean(parentThemeId),
  })

  const initialValues = useMemo(() => {
    if (parentThemeId) {
      return {
        ...initialThemeValues,
        parent_theme: +parentThemeId,
      }
    }
    return initialThemeValues
  }, [parentThemeId]) as ThemeDetail

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/themes">Temi</Link>
            </li>
            {parentTheme && (
              <li className="breadcrumb-item">
                <Link to={`/themes/${parentThemeId}`}>
                  {parentTheme.title[MAIN_LANG]}
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <ThemeForm
          theme={initialValues}
          save={async (theme) => {
            const savedTheme = await createDoc.mutateAsync(theme)
            navigate(`/themes/${savedTheme.id}`)
          }}
        />
      </div>
    </Page>
  )
}
