import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import DocumentCreate from './DocumentCreate'
import DocumentEdit from './DocumentEdit'
import DocumentsLists from './DocumentsList'

export default function Documents() {
  return (
    <Routes>
      <Route index element={<DocumentsLists />} />
      <Route path="new" element={<DocumentCreate />} />
      <Route path=":id" element={<DocumentEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
