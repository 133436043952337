import { useRef } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export interface MoveModalProps {
  isOpen: boolean
  onMove(index: number): void
  toggle?(): void
  onClosed?: () => void
  max: number
  title?: string
}

export default function MoveModal({
  isOpen,
  toggle,
  onClosed,
  onMove,
  max,
  title = 'Muovi'
}: MoveModalProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  function handleSubmit() {
    onMove(Math.min(Math.max(Number(inputRef.current!.value), 1), max) - 1)
  }
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      scrollable={false}
      modalClassName="overflow-hidden"
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit()
          }}
        >
          <div className="form-group">
            <input
              placeholder={`Posizione: (1-${max})`}
              ref={inputRef}
              className="form-control"
              type="number"
              min={0}
              max={max}
            />
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <button onClick={toggle} className="btn btn-outline-primary">
          Annulla
        </button>
        <button
          onClick={() => {
            handleSubmit()
          }}
          className="btn btn-success"
        >
          Conferma
        </button>
      </ModalFooter>
    </Modal>
  )
}
