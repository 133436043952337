import { Link, useNavigate } from 'react-router-dom'
import DocumentForm from '../../components/DocumentForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateDoc } from '../../hooks/docs'

export default function DocumentCreate() {
  const createDoc = useCreateDoc()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/documents">Documenti</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <DocumentForm
          save={async (doc) => {
            const savedDoc = await createDoc.mutateAsync(doc)
            navigate(`/documents/${savedDoc.id}`)
          }}
        />
      </div>
    </Page>
  )
}
