import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  CONTENT_TYPES,
  CONTENT_TYPES_LABELS,
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_LABELS,
  MAIN_LANG,
} from '../../consts'
import { useDeleteDoc, useDocs, useImportDocs } from '../../hooks/docs'
import { useQsFilters } from '../../hooks/filters'
import { format as formatDate } from 'date-fns'
import { Doc } from '../../types'
import Sorter, { SortControl } from '../../components/Sorter'
import MissingTranslations from '../../components/MissingTranslations'
import Paginator from '../../components/Paginator'
import StickyTable from '../../components/StickyTable'
import Page from '../../components/Page'
import Header from '../../components/Header'
import ModalImportDocs from '../../components/ModalImportDocs'
import { toast } from 'react-toastify'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  document_type: params.get('document_type') ?? '',
  content_type: params.get('content_type') ?? '',
  ordering: params.get('ordering') ?? '',
})

function getIconContent(type: String) {
  if (type === 'video') {
    return <i className="bi bi-camera-video-fill me-2"></i>
  } else if (type === 'image') {
    return <i className="bi bi-image me-2"></i>
  } else if (type === 'pdf') {
    return <i className="bi bi-file-earmark-pdf-fill me-2"></i>
  } else {
    return <i className="bi bi-soundwave me-2"></i>
  }
}

const MISSING_TRANSLATIONS_FIELDS = ['title', 'description_plain']

export default function DocumentsList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useDocs(filters)
  const deleteDoc = useDeleteDoc()
  const importDocs = useImportDocs()
  const [modalRm, modalRmActions] = useModalTrigger<Doc>()
  const [modalImport, modalImportActions] = useModalTrigger()

  return (
    <Page padded>
      <div>
        <Header>
          <div className="d-flex align-items-end">
            <h2>Documenti</h2>
            <div className="counter pb-1 ms-2">
              <small>
                {data!.count}
                {' / '}
                {data!.total_count}
              </small>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary me-2"
              onClick={() => {
                modalImportActions.open()
              }}
            >
              <i className="bi bi-file-earmark-arrow-up-fill me-2" />
              Importa
            </button>
            <Link className="btn btn-primary" to="/documents/new">
              <i className="bi bi-plus-circle me-2" /> Aggiungi
            </Link>
          </div>
        </Header>
        <div className="d-flex align-items-center mt-3 mb-3">
          <input
            placeholder="Cerca"
            className="form-control w-auto"
            type="text"
            value={uiFilters.search}
            onChange={(e) =>
              setFiltersDebounced({ search: e.target.value, page: 1 })
            }
          />
          <select
            onChange={(e) =>
              setFilters({ document_type: e.target.value, page: 1 })
            }
            value={uiFilters.document_type}
            className="form-select w-auto ms-3"
          >
            <option></option>
            {DOCUMENT_TYPES.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <select
            onChange={(e) =>
              setFilters({ content_type: e.target.value, page: 1 })
            }
            value={uiFilters.content_type}
            className="form-select w-auto ms-3"
          >
            <option></option>
            {CONTENT_TYPES.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field={`title__${MAIN_LANG}`} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Tipo Documento <SortControl field="document_type" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Tipo Contenuto
                  <SortControl field="content_type" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data
                  <SortControl field="date" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data a
                  <SortControl field="date_to" />
                </div>
              </th>
              <th>Keywords</th>
              <th>Thumb</th>
              <th></th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((doc) => (
            <tr key={doc.id}>
              <td>{doc.id}</td>
              <td>
                <Link to={`/documents/${doc.id}`}>
                  <div className="text-ellipsis">{doc.title[MAIN_LANG]}</div>
                </Link>
                <MissingTranslations
                  data={doc}
                  fields={MISSING_TRANSLATIONS_FIELDS}
                />
              </td>
              <td>{DOCUMENT_TYPES_LABELS[doc.document_type]}</td>
              <td>
                {getIconContent(doc.content_type)}{' '}
                {CONTENT_TYPES_LABELS[doc.content_type]}
              </td>
              <td className="text-nowrap">
                {doc.date && formatDate(new Date(doc.date), 'dd/MM/yyyy')}
              </td>
              <td className="text-nowrap">
                {doc.date_to && formatDate(new Date(doc.date_to), 'dd/MM/yyyy')}
              </td>
              <td>{doc.keywords.join(', ')}</td>
              <td>
                {doc.image_thumb && (
                  <img
                    style={{ height: 65, width: 65, objectFit: 'cover' }}
                    alt="Thumb"
                    src={doc.image_thumb}
                    className="img-thumbnail"
                  />
                )}
              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(doc)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal
        centered
        isOpen={modalRm.isOpen}
        toggle={modalRmActions.toggle}
        onClosed={modalRmActions.onClosed}
      >
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il Documento{' '}
              {modalRm.value.title[MAIN_LANG]} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteDoc.isLoading}
            onClick={async () => {
              await deleteDoc.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <ModalImportDocs
        importDocs={async (file) => {
          const importedData = await importDocs.mutateAsync(file)
          toast.success(
            `${importedData.imported_documents} documenti importati con successo!`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            }
          )
        }}
        isOpen={modalImport.isOpen}
        toggle={modalImportActions.toggle}
        onClosed={modalImportActions.onClosed}
      />
      <Paginator
        count={data!.count}
        currentPage={uiFilters.page}
        goToPage={(page) => setFilters({ page })}
      />
    </Page>
  )
}
