import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link, useSearchParams } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import MissingTranslations from '../../components/MissingTranslations'
import Page from '../../components/Page'
import StickyTable from '../../components/StickyTable'
import { MAIN_LANG } from '../../consts'
import {
  makeSortThemesPayload,
  useDeleteTheme,
  useSortThemes,
  useThemes,
} from '../../hooks/themes'
import { Theme } from '../../types'

interface DraggableRowProps {
  theme: Theme
  onDelete(theme: Theme): void
}

const MISSING_TRANSLATIONS_FIELDS = ['title', 'description_plain']

function DraggableRow({ theme, onDelete }: DraggableRowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: theme.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <tr ref={setNodeRef} style={style}>
      <td>{theme.id}</td>
      <td>
        <Link to={`/themes/${theme.id}`}>{theme.title[MAIN_LANG]}</Link>
        <MissingTranslations
          data={theme}
          fields={MISSING_TRANSLATIONS_FIELDS}
        />
      </td>
      {/* <td>
        {theme.child_themes_data.map((child, i) => (
          <span key={child.id}>
            <Link to={`/themes/${child.id}`}>{child.title[MAIN_LANG]}</Link>
            {i < theme.child_themes_data.length - 1 && <span>{', '}</span>}
          </span>
        ))}
      </td> */}
      <td>
        {theme.cover_image_thumb && (
          <img
            style={{ height: 65, width: 65, objectFit: 'cover' }}
            alt="Thumb"
            src={theme.cover_image_thumb}
            className="img-thumbnail"
          />
        )}
      </td>
      <td>
        {theme.published && (
          <i className="bi bi-check-circle-fill text-success"></i>
        )}
      </td>
      <td>
        <span
          {...listeners}
          ref={setActivatorNodeRef}
          {...attributes}
          className={classNames('sortable-handle', {
            'sortable-dragging': isDragging,
          })}
        >
          <i className="bi bi-arrows-expand"></i>
        </span>
        {theme.child_themes_data.length === 0 && (
          <i
            onClick={() => onDelete(theme)}
            className="ms-2 bi cursor-pointer text-danger bi-trash3-fill"
          />
        )}
      </td>
    </tr>
  )
}

export default function ThemesList() {
  const [queryParams] = useSearchParams()
  const filters = {
    parent_theme: queryParams.get('parent') ?? '',
  }
  const { data: themes } = useThemes(filters)
  const sortThemes = useSortThemes()
  const deleteTheme = useDeleteTheme()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const [modalRm, modalRmActions] = useModalTrigger<Theme>()

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over?.id && active.id !== over.id) {
      sortThemes.mutate({
        payload: makeSortThemesPayload(themes!, +active.id, +over.id),
        filters,
      })
    }
  }

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Temi</h2>
          <div className="counter ms-2 mb-1">
            <small>{themes!.length}</small>
          </div>
        </div>
        <Link className="btn btn-primary" to="/themes/new">
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </Link>
      </Header>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={themes!} strategy={verticalListSortingStrategy}>
          <StickyTable className="flex-1 my-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Titolo</th>
                {/* <th>Sottotemi</th> */}
                <th>Thumb</th>
                <th>Pubblicato</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {themes!.map((theme) => (
                <DraggableRow
                  onDelete={modalRmActions.open}
                  key={theme.id}
                  theme={theme}
                />
              ))}
            </tbody>
          </StickyTable>
        </SortableContext>
      </DndContext>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il Tema{' '}
              {modalRm.value.title[MAIN_LANG]} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteTheme.isLoading}
            onClick={async () => {
              await deleteTheme.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
