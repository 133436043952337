import { TranslatedField } from './types'

export const LANGS = ['it', 'en'] as (keyof TranslatedField)[]

export const LANGS_WITHOUT_DEFAULT = ['en'] as (keyof TranslatedField)[]

export const MAIN_LANG = 'it'

export const PAGE_SIZE = 100

export const EMPTY_LANG_FIELD = LANGS.reduce((obj, lang) => {
  obj[lang] = ''
  return obj
}, {} as TranslatedField)

export const CONTENT_TYPES = [
  ['image', 'Immagine'],
  ['video', 'Video'],
  ['audio', 'Audio'],
  ['pdf', 'PDF'],
]

export const THEME_TYPES = [
  ['tema', 'Tema'],
  ['ricerca', 'Ricerca']
]as const

export const CONTENT_TYPES_LABELS = Object.fromEntries(CONTENT_TYPES) as Record<
  string,
  string
>

export const DOCUMENT_TYPES = [
  ['catalogue', 'Catalogo'],
  ['book', 'Libro'],
  ['letter', 'Lettera'],
  ['poster', 'Locandina'],
  ['card', 'Cartolina'],
  ['notebook', 'Quaderno'],
  ['advertising', 'Pubblicità'],
  ['magazine', 'Rivista'],
  ['manual', 'Manuale'],
  ['photo', 'Foto'],
  ['film', 'Film'],
  ['news', 'Notizia'],
  ['document', 'Documento'],
  ['article', 'Articolo'],
  ['program', 'Programma'],
  ['video_interview', 'Video intervista'],
  ['clip', 'Clip']
]

export const DOCUMENT_TYPES_LABELS = Object.fromEntries(
  DOCUMENT_TYPES
) as Record<string, string>
