// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './theme.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

if (process.env.REACT_APP_SITE_VERSION) {
  console.log(
    `%cADMIN HM100 %c${process.env.REACT_APP_SITE_VERSION}`,
    'color:#FFD700; background: #7A0000;',
    'color:white;font-weight: bold;background: #7A0000;'
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
