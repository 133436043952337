import { Suspense, useCallback, useMemo, useState } from 'react'
import keyBy from 'lodash/keyBy'
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import {
  CONTENT_TYPES,
  CONTENT_TYPES_LABELS,
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_LABELS,
  MAIN_LANG,
} from '../consts'
import { useDocs } from '../hooks/docs'
import { useStateFilters } from '../hooks/filters'
import { format as formatDate } from 'date-fns'
import { Doc } from '../types'
import Paginator from './Paginator'
import Sorter, { SortControl } from './Sorter'
import StickyTable from './StickyTable'

interface DocumentsPickerProps {
  initialSelectedDocuments: Doc[]
  onDocumentsChange(docs: Doc[]): void
  excludeId?: number
}

function DocumentsPicker({
  initialSelectedDocuments,
  onDocumentsChange,
  excludeId,
}: DocumentsPickerProps) {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useStateFilters({
      page: 1,
      ordering: '',
      search: '',
      document_type: '',
      content_type: '',
    })
  const { data } = useDocs(filters)
  const [selectedDocuments, setSelectedDocuments] = useState(
    initialSelectedDocuments
  )
  const selectedDocsById = useMemo(
    () => keyBy(selectedDocuments, 'id'),
    [selectedDocuments]
  )

  return (
    <>
      <ModalBody>
        <div
          className="d-flex flex-column"
          style={{ maxHeight: 'calc(100vh - 220px)' }}
        >
          <div className="d-flex align-items-center p-2">
            <input
              placeholder="Cerca"
              className="form-control w-auto"
              type="text"
              value={uiFilters.search}
              onChange={(e) =>
                setFiltersDebounced({ search: e.target.value, page: 1 })
              }
            />
            <select
              onChange={(e) =>
                setFilters({ document_type: e.target.value, page: 1 })
              }
              value={uiFilters.document_type}
              className="form-select w-auto ms-3"
            >
              <option></option>
              {DOCUMENT_TYPES.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <select
              onChange={(e) =>
                setFilters({ content_type: e.target.value, page: 1 })
              }
              value={uiFilters.content_type}
              className="form-select w-auto ms-3"
            >
              <option></option>
              {CONTENT_TYPES.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <StickyTable className='mx-2 mt-2'>
            <thead>
              <Sorter
                value={uiFilters.ordering}
                onSortChange={(ordering) =>
                  setFilters({
                    ordering,
                    page: 1,
                  })
                }
              >
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      Titolo
                      <SortControl field={`title__${MAIN_LANG}`} />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Tipo Documento <SortControl field="document_type" />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Tipo Contenuto
                      <SortControl field="content_type" />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Data
                      <SortControl field="date" />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Data a
                      <SortControl field="date_to" />
                    </div>
                  </th>
                  <th>Keywords</th>
                  <th>Thumb</th>
                </tr>
              </Sorter>
            </thead>
            <tbody>
              {data!.results.map((doc) =>
                excludeId && doc.id === excludeId ? null : (
                  <tr key={doc.id}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDocuments((prev) => prev.concat(doc))
                          } else {
                            setSelectedDocuments((prev) =>
                              prev.filter((d) => d.id !== doc.id)
                            )
                          }
                        }}
                        checked={Boolean(selectedDocsById[doc.id])}
                      />
                    </td>
                    <td>{doc.id}</td>
                    <td>{doc.title[MAIN_LANG]}</td>
                    <td>{DOCUMENT_TYPES_LABELS[doc.document_type]}</td>
                    <td>{CONTENT_TYPES_LABELS[doc.content_type]}</td>
                    <td>
                      {doc.date && formatDate(new Date(doc.date), 'yyyy-MM-dd')}
                    </td>
                    <td>
                      {doc.date_to &&
                        formatDate(new Date(doc.date_to), 'yyyy-MM-dd')}
                    </td>
                    <td>{doc.keywords.join(', ')}</td>
                    <td>
                      {doc.image_thumb && (
                        <img
                          style={{
                            height: 65,
                            width: 65,
                            objectFit: 'cover',
                          }}
                          alt="Thumb"
                          src={doc.image_thumb}
                          className="img-thumbnail"
                        />
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </StickyTable>
          <Paginator
            count={data!.count}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={() => {
            onDocumentsChange(selectedDocuments)
          }}
          className="btn btn-success"
        >
          Conferma
        </button>
      </ModalFooter>
    </>
  )
}

interface DocumentsPickerModalProps {
  isOpen: boolean
  selectedDocuments: Doc[]
  onDocumentsChange(docs: Doc[]): void
  toggle?(): void
  onClosed?: () => void
  excludeId?: number
}

export default function DocumentsPickerModal({
  isOpen,
  toggle,
  onClosed,
  selectedDocuments,
  onDocumentsChange,
  excludeId,
}: DocumentsPickerModalProps) {
  const handleDocsChange = useCallback(
    (docs: Doc[]) => {
      onDocumentsChange(docs)
      toggle && toggle()
    },
    [onDocumentsChange, toggle]
  )
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      scrollable={false}
      modalClassName="overflow-hidden"
    >
      <ModalHeader toggle={toggle}>Seleziona Documenti</ModalHeader>
      <Suspense
        fallback={
          <div className="w-100 text-center p-5">
            <Spinner color="primary" />
          </div>
        }
      >
        <DocumentsPicker
          initialSelectedDocuments={selectedDocuments}
          onDocumentsChange={handleDocsChange}
          excludeId={excludeId}
        />
      </Suspense>
    </Modal>
  )
}
