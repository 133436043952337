import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { FieldArray, useFormikContext } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { DOCUMENT_TYPES_LABELS, MAIN_LANG } from '../consts'
import { Doc } from '../types'
import DocumentsPickerModal from './DocumentsPickerModal'
import classNames from 'classnames'
import { findIndex } from 'lodash'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import MoveModal from './MoveModal'
import { useCallback } from 'react'

interface DraggableDocItemProps {
  doc: Doc
  index: number
  onRemove(index: number): void
  onMove(index: number): void
}
function DraggableDocItem({
  doc,
  index,
  onRemove,
  onMove,
}: DraggableDocItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: doc.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div
      className="border-bottom row m-0 p-0 align-items-center py-2"
      ref={setNodeRef}
      style={style}
    >
      <div className="col-md-5">{doc.title[MAIN_LANG]}</div>
      <div className="col-md-4">{DOCUMENT_TYPES_LABELS[doc.document_type]}</div>
      <div className="col-md-3 d-flex justify-content-end align-items-center">
        <span
          {...listeners}
          ref={setActivatorNodeRef}
          {...attributes}
          title="Ordina documento"
          className={classNames('sortable-handle', {
            'sortable-dragging': isDragging,
          })}
        >
          <i className="bi bi-arrows-expand"></i>
        </span>
        <button
          className="btn btn-sm ps-3"
          type="button"
          title="Sposta il documento"
          onClick={() => onMove(index)}
        >
          <i className="bi bi-arrows-move"></i>
        </button>
        <button
          className="btn btn-sm ps-2"
          type="button"
          title="Scollega il documento"
          onClick={() => onRemove(index)}
        >
          <i className="bi bi-unlock-fill"></i>
        </button>
      </div>
    </div>
  )
}

interface ValuesWithRelatedDocuments {
  related_documents_data: Doc[]
}

interface SortableRelatedDocumentsProps {
  excludeId?: number
}

export default function SortableRelatedDocuments({
  excludeId,
}: SortableRelatedDocumentsProps) {
  const { values, setFieldValue } =
    useFormikContext<ValuesWithRelatedDocuments>()

  const [modalPick, modalPickActions] = useModalTrigger()
  const [modalMove, modalMoveActions] = useModalTrigger<number>()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over?.id && active.id !== over.id) {
      const fromIndex = findIndex(values.related_documents_data, {
        id: +active.id,
      })
      const toIndex = findIndex(values.related_documents_data, {
        id: +over.id,
      })
      setFieldValue(
        'related_documents_data',
        arrayMove(values.related_documents_data, fromIndex, toIndex)
      )
    }
  }

  const handleMove = useCallback(
    (toIndex: number) => {
      const fromIndex = modalMove.value!
      setFieldValue(
        'related_documents_data',
        arrayMove(values.related_documents_data, fromIndex, toIndex)
      )
      modalMoveActions.close()
    },
    [
      modalMove.value,
      modalMoveActions,
      setFieldValue,
      values.related_documents_data,
    ]
  )

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext
          items={values.related_documents_data}
          strategy={verticalListSortingStrategy}
        >
          <FieldArray
            name="related_documents_data"
            render={(arrayHelpers) => (
              <>
                <div className="col-md-12 mb-4 mt-4 d-flex align-items-center">
                  <h4>Documenti Collegati</h4>
                  <button
                    className="ms-3 btn btn-sm d-flex align-items-center btn-primary"
                    type="button"
                    onClick={() => modalPickActions.open()}
                  >
                    <i className="bi bi-lock-fill me-2" /> Collega
                  </button>
                </div>
                <div className="col-md-12 mb-4">
                  <div>
                    {values.related_documents_data.map((doc, i) => (
                      <DraggableDocItem
                        index={i}
                        onRemove={arrayHelpers.remove}
                        onMove={modalMoveActions.open}
                        key={doc.id}
                        doc={doc}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          />
        </SortableContext>
      </DndContext>
      <DocumentsPickerModal
        excludeId={excludeId}
        onDocumentsChange={(docs) =>
          setFieldValue('related_documents_data', docs)
        }
        selectedDocuments={values.related_documents_data}
        isOpen={modalPick.isOpen}
        toggle={modalPickActions.toggle}
        onClosed={modalPickActions.onClosed}
      />
      <MoveModal
        title="Muovi Documento"
        max={values.related_documents_data.length}
        isOpen={modalMove.isOpen}
        toggle={modalMoveActions.toggle}
        onClosed={modalMoveActions.onClosed}
        onMove={handleMove}
      />
    </>
  )
}
