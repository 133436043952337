import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
  THEME_TYPES,
} from '../consts'
import { BaseThemeDetail, ThemeDetail } from '../types'
import Footer from './Footer'
import {
  CheckBoxField,
  convertRichTranslatedFieldToPlainText,
  FileField,
  InputField,
  RichTextField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import SortableRelatedDocuments from './SortableRelatedDocuments'
import { VideoPrview } from './VideoPreview'

const ThemeSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),

  video_source: Yup.string().required().label('Video principale url or id'),

  published: Yup.boolean().label('Pubblicato'),

  parent_theme: Yup.string().nullable().label('Tema padre'),

  cover_image: Yup.mixed().nullable().label('Immagine di copertina'),

  type: Yup.string().required().label('Tipo'),
})

interface Props {
  theme?: ThemeDetail
  save: (theme: ThemeDetail) => Promise<unknown>
}

export const initialThemeValues: BaseThemeDetail = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  description_short: EMPTY_LANG_FIELD,
  description_plain: EMPTY_LANG_FIELD,
  bibliography: EMPTY_LANG_FIELD,
  credits: EMPTY_LANG_FIELD,
  main_video: '',
  video_source: '',
  parent_theme: null,
  published: false,
  related_documents_data: [],
  child_themes_data: [],
  cover_image: null,
  type: 'tema',
  in_evidence: false,
  url_content: '',
}

export default function ThemeForm({ theme, save }: Props) {
  const [lang, setLang] = useState('en')

  return (
    <Formik
      enableReinitialize
      onSubmit={(theme, { setErrors }) =>
        save({
          ...theme,
          description_plain: convertRichTranslatedFieldToPlainText(
            theme.description
          ),
        }).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={ThemeSchema}
      initialValues={(theme ?? initialThemeValues) as ThemeDetail}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione breve (Lista temi) [it]`}
                name={`description_short.it`}
                component={TextareaField}
                rows={6}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione breve (Lista temi) [${lang}]`}
                name={`description_short.${lang}`}
                component={TextareaField}
                rows={6}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                label={`Descrizione [it] (Dettaglio tema)`}
                name={`description.it`}
                component={RichTextField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Descrizione [${lang}] (Dettaglio tema)`}
                name={`description.${lang}`}
                component={RichTextField}
                rows={6}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>
            <div className="col-md-6">
              <Field
                required
                label="Video principale url or id"
                component={InputField}
                name="video_source"
              />
              {values.url_content && <VideoPrview url={values.url_content} />}
            </div>
            <div className="col-md-6">
              <Field
                label="Immagine di copertina"
                component={FileField}
                fileType="image"
                name="cover_image"
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Bibliografia [it]"
                component={TextareaField}
                rows={7}
                name="bibliography.it"
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Bibliografia [${lang}] (Dettaglio tema)`}
                name={`bibliography.${lang}`}
                component={TextareaField}
                rows={7}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Crediti [it] (Dettaglio tema)"
                rows={7}
                component={TextareaField}
                name="credits.it"
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Crediti [${lang}] (Dettaglio tema)`}
                rows={7}
                component={TextareaField}
                name={`credits.${lang}`}
              />
            </div>
            <div className="col-md-6">
              <Field
                required
                label={'Tipo'}
                name={'type'}
                component={SelectField}
              >
                <option value=""></option>
                {THEME_TYPES.map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-6">
              <Field
                label="Pubblicato"
                component={CheckBoxField}
                name="published"
              />
            </div>
            <div className="col-md-6">
              <Field
                label="In evidenza?"
                component={CheckBoxField}
                name="in_evidence"
              />
            </div>
          </div>
          <SortableRelatedDocuments />
          {/* {!values.parent_theme && (
            <SortableChildThemes parentId={theme?.id} params={themesParams} />
          )} */}
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
