import { Field, Formik } from 'formik'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { resetPassword, useCheckResetPasswordToken } from '../hooks/password'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../components/form'
import background from './Login/assets/background-100.png'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password è un campo obbligatorio')
    .min(8, 'La password deve essere lunga almeno 8 caratteri.'),
  repeatPassword: Yup.string()
    .required('Conferma Password è un campo obbligatorio')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

const initialValues = {
  password: '',
  repeatPassword: '',
}

export default function ResetPassword() {
  const { token } = useParams()
  const { isError, isLoading } = useCheckResetPasswordToken(token!)
  const [resetOk, setResetOk] = useState(false)

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="pt-5" style={{ maxWidth: 600, margin: 'auto' }}>
        <div className="alert alert-danger">Link scaduto o invalido.</div>
      </div>
    )
  }

  return (
    <div
      className="h-100 w-100"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="row w-100 h-100 align-items-center p-2">
        <div className="col-md-4 offset-md-4">
          <div className="card">
            <div className="card-header bg-white card-header-login">
              <h5>Reset Password</h5>
            </div>
            <div className="card-body">
              {resetOk ? (
                <div className="alert alert-success mt-2">
                  Password resettata con successo.
                  <br />
                  Vai al <Link to="/login">Login</Link>.
                </div>
              ) : (
                <Formik
                  initialTouched={{ password: true, repeatPassword: true }}
                  onSubmit={(data, { setErrors }) =>
                    resetPassword(token!, data.password).then(
                      () => {
                        setResetOk(true)
                      },
                      (error) => {
                        setErrors(transformErrorForForm(error))
                      }
                    )
                  }
                  validationSchema={ResetPasswordSchema}
                  initialValues={initialValues}
                >
                  {({ handleSubmit, errors, isSubmitting, isValid }) => (
                    <form onSubmit={handleSubmit}>
                      <p>Inserisci una nuova password.</p>
                      <Field
                        label="Password"
                        type="password"
                        name="password"
                        component={InputField}
                      />
                      <Field
                        label="Conferma Password"
                        type="password"
                        name="repeatPassword"
                        component={InputField}
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <button
                          type="submit"
                          disabled={isSubmitting || !isValid}
                          className="btn btn-primary"
                        >
                          Reset Password
                        </button>
                      </div>
                      {(errors as any).__noFieldsServerError && (
                        <div className="alert alert-danger mt-2">
                          Impossibile eseguire la proedura di reset password,
                          riprova più tardi.
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
