import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { MAIN_LANG } from '../../consts'
import { format as formatDate } from 'date-fns'
import { useCalendarDocs } from '../../hooks/docs'
import { useQsFilters } from '../../hooks/filters'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import DocumentPickerModal from '../../components/DocumentPickerModal'
import {
  useCreateCalendarDoc,
  useDeleteCalendarDoc,
  useUpdateCalendarDoc,
} from '../../hooks/calendar'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseCalendarDocument, CalendarDocument } from '../../types'
import { toast } from 'react-toastify'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
})

export default function Calendar() {
  const { uiFilters, filters, setFilters } = useQsFilters(initFilters)
  const { data } = useCalendarDocs(filters)
  const createDocCalendar = useCreateCalendarDoc()
  const deleteDocCalendar = useDeleteCalendarDoc()
  const updateDocCalendar = useUpdateCalendarDoc()
  const [modalPick, modalPickActions] = useModalTrigger<BaseCalendarDocument>()
  const [modalUpdatePick, modaUpdatePickActions] =
    useModalTrigger<CalendarDocument>()
  const [modalRm, modalRmActions] = useModalTrigger<CalendarDocument>()
  return (
    <Page padded>
      <div>
        <Header>
          <div className="d-flex align-items-end">
            <h2>Calendario Documenti</h2>
            <div className="counter pb-1 ms-2">
              <small>
                {data!.count}
                {' / '}
                {data!.total_count}
              </small>
            </div>
          </div>
          <div>
            <button
              onClick={() => modalPickActions.open()}
              className="btn btn-primary me-2"
            >
              Aggiungi
            </button>
            {/* <button
              className="btn btn-primary me-2"
              onClick={() => {
                modalImportActions.open()
              }}
            >
              <i className="bi bi-file-earmark-arrow-up-fill me-2" />
              Importa
            </button>
            <Link className="btn btn-primary" to="/documents/new">
              <i className="bi bi-plus-circle me-2" /> Aggiungi
            </Link> */}
          </div>
        </Header>
      </div>
      <StickyTable className="flex-1 mt-3">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field={`document__title__${MAIN_LANG}`} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Data
                  <SortControl field={`date`} />
                </div>
              </th>
              <th>Thumb</th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((calendar) => (
            <tr key={calendar.document_data.id}>
              <td>{calendar.document_data.id}</td>
              <td>
                <Link to={`/documents/${calendar.document_data.id}`}>
                  <div>{calendar.document_data.title[MAIN_LANG]}</div>
                </Link>
              </td>
              <td>
                {calendar.date &&
                  formatDate(new Date(calendar.date), 'dd/MM/yyyy')}
              </td>
              <td>
                {calendar.document_data.image_thumb && (
                  <img
                    style={{ height: 65, width: 65, objectFit: 'cover' }}
                    alt="Thumb"
                    src={calendar.document_data.image_thumb}
                    className="img-thumbnail"
                  />
                )}
              </td>
              <td>
                <i
                  onClick={() => modaUpdatePickActions.open(calendar)}
                  className="bi cursor-pointer text-primary bi-pencil-fill me-3"
                ></i>
                <i
                  onClick={() => modalRmActions.open(calendar)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Paginator
        count={data!.count}
        currentPage={uiFilters.page}
        goToPage={(page) => setFilters({ page })}
      />
      <DocumentPickerModal
        onConfirm={async (calendar) => {
          await createDocCalendar
            .mutateAsync(calendar)
            .then(() => {
              toast.success('Documento aggiunto al calendario con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            })
          modalPickActions.toggle()
        }}
        isOpen={modalPick.isOpen}
        toggle={modalPickActions.toggle}
        onClosed={modalPickActions.onClosed}
      />
      <DocumentPickerModal
        onConfirm={async (calendar) => {
          await updateDocCalendar.mutateAsync({
            id: modalUpdatePick.value!.id,
            date: calendar.date,
            document: calendar.document,
          })
          if (updateDocCalendar.isSuccess) {
            toast.success('Documento aggiornato a calendario con successo!', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            })
          }
          modaUpdatePickActions.toggle()
        }}
        calendar={modalUpdatePick.value || undefined}
        isOpen={modalUpdatePick.isOpen}
        isError={updateDocCalendar.isError}
        toggle={modaUpdatePickActions.toggle}
        onClosed={modaUpdatePickActions.onClosed}
      />
      <Modal
        centered
        isOpen={modalRm.isOpen}
        toggle={modalRmActions.toggle}
        onClosed={modalRmActions.onClosed}
      >
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare documento{' '}
              {modalRm.value.document_data.title[MAIN_LANG]} a calendario in
              data {formatDate(new Date(modalRm.value.date), 'dd/MM/yyyy')} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteDocCalendar.isLoading}
            onClick={async () => {
              if (modalRm.value?.id) {
                await deleteDocCalendar.mutateAsync(modalRm.value!.id)
                modalRmActions.close()
              }
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
