import { FastField, Field, Formik, useFormikContext } from 'formik'
import { BaseDocDetail, ContentTypesUnion, DocDetail } from '../types'
import {
  DateField,
  FileField,
  InputField,
  SelectField,
  transformErrorForForm,
  RichTextField,
  StringListField,
  convertRichTranslatedFieldToPlainText,
} from './form'
import * as Yup from 'yup'
import {
  CONTENT_TYPES,
  DOCUMENT_TYPES,
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { docDataConfig, DocDataConfigItem } from './DocumentDataFormConfig'
import { createElement, useState } from 'react'
import SortableRelatedDocuments from './SortableRelatedDocuments'
import classNames from 'classnames'
import Footer from './Footer'
import { VideoPrview } from './VideoPreview'

const VIDEO_CONTENT_TYPES: ContentTypesUnion[] = ['video']

const initialValues: BaseDocDetail = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  description_plain: EMPTY_LANG_FIELD,
  content_type: '',
  document_type: '',
  url_content: '',
  video_source: '',
  file_content: null,
  date: null,
  date_to: null,
  data: {},
  related_documents_data: [],
  keywords: [],
}

const flattyDataSchema = Object.keys(docDataConfig).reduce((all, k) => {
  const configItem = docDataConfig[k]
  configItem.types.forEach((t) => {
    all[t] = configItem
  })
  return all
}, {} as Record<string, DocDataConfigItem>)

const DocSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),
  content_type: Yup.string().required().label('Tipo Contenuto'),
  video_source: Yup.string()
    .nullable()
    .label('Video')
    .when('content_type', {
      is: (value: ContentTypesUnion) => VIDEO_CONTENT_TYPES.includes(value),
      then: (schema) => schema.required(),
    }),
  file_content: Yup.mixed()
    .label('File')
    .when('content_type', {
      is: (value: ContentTypesUnion) => !VIDEO_CONTENT_TYPES.includes(value),
      then: (schema) => schema.required(),
    }),

  document_type: Yup.string().required().label('Tipo Documento'),

  date: Yup.string().nullable().label('Data'),

  date_to: Yup.string().nullable().label('Data a'),

  data: Yup.object().when('document_type', (type: string) => {
    if (type) {
      const config = flattyDataSchema[type]
      if (config) {
        return config.schema
      }
    }
    return Yup.object()
  }),

  keywords: Yup.mixed().label('Keywords'),
})

interface Props {
  doc?: DocDetail
  save: (doc: DocDetail) => Promise<unknown>
}

function DocumentDataForm() {
  const { values } = useFormikContext<DocDetail>()

  if (flattyDataSchema[values.document_type]) {
    const configItem = flattyDataSchema[values.document_type]
    return createElement(configItem.component)
  }

  return null
}

export default function DocumentForm({ save, doc }: Props) {
  const [lang, setLang] = useState('en')
  return (
    <Formik
      enableReinitialize
      validationSchema={DocSchema}
      onSubmit={(doc, { setErrors }) =>
        save({
          ...doc,
          description_plain: convertRichTranslatedFieldToPlainText(
            doc.description
          ),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(doc ?? initialValues) as DocDetail}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <FastField
                label={`Descrizione [it]`}
                name={`description.it`}
                component={RichTextField}
              />
            </div>
            <div className="col-md-6">
              <Field
                required={lang === MAIN_LANG}
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <FastField
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                component={RichTextField}
              />
            </div>
            <div className="col-md-3">
              <Field name="date" label={'Da'} component={DateField} />
            </div>
            <div className="col-md-3">
              <Field name="date_to" label={'A'} component={DateField} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Tipo di contenuto</h4>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={'Tipo Contenuto'}
                name={'content_type'}
                component={SelectField}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const prevValue = values.content_type
                  const value = e.target.value as ContentTypesUnion
                  if (value) {
                    if (prevValue) {
                      const isUrl = VIDEO_CONTENT_TYPES.includes(value)
                      const prevIsUrl = VIDEO_CONTENT_TYPES.includes(prevValue)
                      if (isUrl !== prevIsUrl) {
                        if (isUrl) {
                          setFieldValue('file_content', null)
                        } else {
                          setFieldValue('video_source', '')
                          setFieldValue('url_content', '')
                        }
                      }
                    }
                  } else {
                    setFieldValue('file_content', null)
                    setFieldValue('video_source', '')
                    setFieldValue('url_content', '')
                  }
                }}
              >
                <option value=""></option>
                {CONTENT_TYPES.map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-6">
              {values.content_type &&
                (VIDEO_CONTENT_TYPES.includes(values.content_type) ? (
                  <div>
                    <Field
                      required
                      label={'Video'}
                      placeholder="Vimeo url or id"
                      name={`video_source`}
                      component={InputField}
                    />
                    {values.url_content && (
                      <VideoPrview url={values.url_content} />
                    )}
                  </div>
                ) : (
                  <Field
                    required
                    label={'File'}
                    name={`file_content`}
                    component={FileField}
                    fileType={values.content_type}
                  />
                ))}
            </div>
            <div className="col-md-6">
              <Field
                required
                label={'Tipo Documento'}
                name={'document_type'}
                component={SelectField}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const prevValue = values.document_type
                  const value = e.target.value as string
                  if (value) {
                    const config = flattyDataSchema[value]
                    let configHasChanged = false
                    if (prevValue) {
                      const prevConfig = flattyDataSchema[prevValue]
                      configHasChanged = config !== prevConfig
                    } else {
                      configHasChanged = true
                    }
                    if (configHasChanged) {
                      setFieldValue('data', config.initialData, false)
                    }
                  } else {
                    setFieldValue('data', {}, false)
                  }
                }}
              >
                <option value=""></option>
                {DOCUMENT_TYPES.map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-6">
              <DocumentDataForm />
            </div>
            <div className="col-md-6">
              <Field
                name="keywords"
                component={StringListField}
                label={'Keywords'}
              />
            </div>
          </div>

          <SortableRelatedDocuments excludeId={doc?.id} />
          {doc && doc.text_tokens && (
            <div>
              <h4>Token Testuali</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Testo</th>
                    <th>Lingua</th>
                  </tr>
                </thead>
                <tbody>
                  {doc.text_tokens.map((t) => (
                    <tr key={t.id}>
                      <td>{t.text}</td>
                      <td>{t.lang}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
