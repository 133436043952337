import { Field } from 'formik'
import { ComponentType } from 'react'
import { AnySchema } from 'yup'
import * as Yup from 'yup'
import { InputField, SelectField } from './form'
import { DocDataDocument, DocDataNotVideo } from '../types'

export interface DocDataConfigItem {
  schema: AnySchema
  types: string[]
  component: ComponentType
  initialData: Record<string, any>
}

export const docDataConfig: Record<string, DocDataConfigItem> = {
  document_not_video: {
    schema: Yup.object({
      author: Yup.string().label('Autore'),
      archive: Yup.string().label('Archivio'),
    }),
    types: ['catalogue','book','letter','poster','card','notebook','advertising','magazine','manual','photo','news','document','article','program'],
    component: DataDocumentNotVideo,
    initialData: {
      archive: '',
      autore: '',
    } as DocDataNotVideo,
  },
  document: {
    schema: Yup.object({
      format: Yup.string().label('Formato'),
      duration: Yup.string().label('Durata'),
      archive: Yup.string().label('Archivio'),
      author: Yup.string().label('Autore'),
      sound_it: Yup.string().label('Suono'),
      sound_en: Yup.string().label('Suono'),
    }),
    types: ['film', 'video_interview','clip'],
    component: DataDocumentForm,
    initialData: {
      archive: '',
      duration: '',
      format: '',
      author: '',
      sound_it: '',
      sound_en: ''
    } as DocDataDocument,
  },
}

function DataDocumentNotVideo() {
  return (
    <div>
      <Field
        label={'Autore'}
        name={`data.author`}
        component={InputField}
      />
      <Field
        label={'Archivio'}
        name={`data.archive`}
        component={InputField}
      />
    </div>
  )
}

const FORMATS = [
  '8mm',
  'Super8',
  '16mm',
  '9,5mm',
  '35mm',
  'VIDEO TAPE 1/2"',
  'VIDEO TAPE 1/4"',
  'BETACAM',
  'BETAMAX',
  'U-MATIC',
  'Hi8',
  'Video8',
  'Digitale HD',
  'Digitale 2k',
  'Digitale 4k',
  'n.d',
]

function DataDocumentForm() {
  return (
    <div>
      <Field label={'Archivio'} name={`data.archive`} component={InputField} />
      <Field label={'Durata'} name={`data.duration`} component={InputField} />
      <Field label={'Formato'} name={`data.format`} component={SelectField}>
        <option></option>
        {FORMATS.map((format) => (
          <option key={format}>{format}</option>
        ))}
      </Field>
      <Field label={'Autore'} name={`data.author`} component={InputField} />
      <Field label={'Suono [it]'} name={`data.sound.it`} component={InputField} />
      <Field label={'Suono [en]'} name={`data.sound.en`} component={InputField} />
    </div>
  )
}
