import { arrayMove } from '@dnd-kit/sortable'
import axios from 'axios'
import findIndex from 'lodash/findIndex'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Theme, ThemeDetail } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

interface UseThemesOptions {
  ns?: string
}

const defaultThemesOptions: UseThemesOptions = {
  ns: 'themes',
}

export function useThemes(
  params: Record<string, any> = {},
  options?: UseThemesOptions
) {
  const deOptions = { ...defaultThemesOptions, ...options }
  return useQuery<Theme[]>(
    [deOptions.ns, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/themes/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useTheme(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<ThemeDetail>(
    ['theme', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/themes/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteTheme() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/themes/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['themes'])
      },
    }
  )
}

const makeThemeFormData = makeFormDataEncoder({
  fileFields: ['cover_image'],
  excludeFields: [
    'cover_image_thumb',
    'cover_image_medium',
    'cover_image_preview',
    'order',
    'updated_at',
    'parent_theme_data',
  ],
  walker(key, value, fm) {
    if (key === 'child_themes_data' && Array.isArray(value)) {
      value.forEach((v) => {
        fm.append('child_themes', v.id)
      })
      return true
    }
    return false
  },
})

export function useCreateTheme() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (theme: ThemeDetail) =>
        axios
          .post(`/api/admin/themes/`, makeThemeFormData(theme), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ThemeDetail)
    ),
    {
      onSuccess(savedTheme) {
        client.invalidateQueries(['themes'])
        client.invalidateQueries(['theme'], {
          predicate: (q) => q.queryKey[1] !== savedTheme.id,
        })
        client.setQueryData(['theme', savedTheme.id], savedTheme)
      },
    }
  )
}

export function useUpdateTheme() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (theme: ThemeDetail) =>
        axios
          .put(`/api/admin/themes/${theme.id}/`, makeThemeFormData(theme), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ThemeDetail)
    ),
    {
      onSuccess(savedTheme) {
        client.invalidateQueries(['themes'])
        client.invalidateQueries(['theme'], {
          predicate: (q) => q.queryKey[1] !== savedTheme.id,
        })
        client.setQueryData(['theme', savedTheme.id], savedTheme)
      },
    }
  )
}

export interface SortThemesPayload {
  // New sorted state
  nextThemes: Theme[]
  // Payload to server
  serverSortThemes: [number, number][]
}
export interface SortThemesMutationParam {
  // Sort themes payload
  payload: SortThemesPayload
  // Filters themes params
  filters?: Record<string, any>
}

export function makeSortThemesPayload(
  themes: Theme[],
  fromId: number,
  toId: number
): SortThemesPayload {
  // NOTE: Can be optimized but the numer or record will be little
  // so for now this solution is ok
  const oldIndex = findIndex(themes!, { id: fromId })
  const newIndex = findIndex(themes!, { id: toId })

  // Move the array position
  const movedThemes = arrayMove(themes!, oldIndex, newIndex)
  // Find the min order in the collection...
  let iterOrder = Math.min(...movedThemes.map((s) => s.order))
  // New themes state
  const nextThemes: Theme[] = []
  // Payload to server
  const serverSortThemes: [number, number][] = []
  movedThemes.forEach((t) => {
    if (t.order === iterOrder) {
      nextThemes.push(t)
    } else {
      nextThemes.push({ ...t, order: iterOrder })
      serverSortThemes.push([t.id, iterOrder])
    }
    iterOrder++
  })
  return {
    nextThemes,
    serverSortThemes,
  }
}

export function useSortThemes() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ payload }: SortThemesMutationParam) =>
          axios
            .put(
              `/api/admin/themes/sort/`,
              {
                themes: payload.serverSortThemes,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onMutate: ({ payload: { nextThemes }, filters }) => {
        const key = ['themes', filters ?? {}]
        const prevThemes = client.getQueryData(key) as Theme[]
        client.setQueryData(key, nextThemes)
        return { prevThemes }
      },
      onError(_, { filters }, ctx) {
        // Roollback
        const key = ['themes', filters ?? {}]
        client.setQueryData(key, ctx!.prevThemes)
      },
    }
  )
}
