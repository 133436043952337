import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import ThemeCreate from './ThemeCreate'
import ThemeEdit from './ThemeEdit'
import ThemesList from './ThemesList'

export default function Themes() {
  return (
    <Routes>
      <Route index element={<ThemesList />} />
      <Route path="new" element={<ThemeCreate />} />
      <Route path=":id" element={<ThemeEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
